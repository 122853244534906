import {
  PaletteColor,
  PaletteColorOptions,
  Shadows,
  ThemeOptions,
  createTheme,
} from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Palette {
    border: PaletteColor;
  }
  interface PaletteOptions {
    border?: PaletteColorOptions;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    "2xl": true;
    "3xl": true;
    "4xl": true;
    "5xl": true;
  }
}

export type DegType = "t" | "tr" | "tl" | "b" | "br" | "bl" | "r" | "l" | number;

interface DefaultThemePalette {
  primaryDark: string;
  primaryMain: string;
  primaryLight: string;

  secondaryDark: string;
  secondaryMain: string;
  secondaryLight: string;

  errorDark?: string;
  errorMain?: string;
  errorLight?: string;

  successDark?: string;
  successMain?: string;
  successLight?: string;

  warningDark?: string;
  warningMain?: string;
  warningLight?: string;

  white?: string;
  black?: string;

  grey50?: string;
  grey100?: string;
  grey200?: string;
  grey300?: string;
  grey400?: string;
  grey500?: string;
  grey600?: string;
  grey700?: string;
  grey800?: string;
  grey900?: string;

  extensions?: Omit<ThemeOptions["palette"], "border">;
}

interface DefaultThemeFont {
  header: string;
  text: string;
}

interface DefaultThemeWeight {
  label: number;
  paragraph: number;
}

export interface DefaultThemeParams {
  palette: DefaultThemePalette;
  font: DefaultThemeFont;
  spacing?: number;
  radius?: number;
  shadows?: Shadows;
  weight?: DefaultThemeWeight;
}

const BASE_ERROR_DARK = "#B91C1C";
const BASE_ERROR_MAIN = "#EF4444";
const BASE_ERROR_LIGHT = "#FCA5A5";

const BASE_SUCCESS_DARK = "#047857";
const BASE_SUCCESS_MAIN = "#10B981";
const BASE_SUCCESS_LIGHT = "#6EE7B7";

const BASE_WARNING_DARK = "#CA8A04";
const BASE_WARNING_MAIN = "#FACC15";
const BASE_WARNING_LIGHT = "#FEF08A";

const BASE_WHITE = "#FFFFFF";
const BASE_BLACK = "#000000";

const BASE_GREY_50 = "#f9fafb";
const BASE_GREY_100 = "#f3f4f6";
const BASE_GREY_200 = "#e5e7eb";
const BASE_GREY_300 = "#d1d5db";
const BASE_GREY_400 = "#9ca3af";
const BASE_GREY_500 = "#6b7280";
const BASE_GREY_600 = "#4b5563";
const BASE_GREY_700 = "#374151";
const BASE_GREY_800 = "#1f2937";
const BASE_GREY_900 = "#111827";

const BASE_SPACING = 8;
const BASE_RADIUS = 4;

export const BASE_SHADOWS = [
  "none",
  "0 1px 2px 0 rgb(0, 0, 0, 0.05)",
  "0 1px 3px 0 rgb(0, 0, 0, 0.1), 0 1px 2px -1px rgb(0, 0, 0, 0.1)",
  "0 4px 6px -1px rgb(0, 0, 0, 0.1), 0 2px 4px -2px rgb(0, 0, 0, 0.1)",
  "0 10px 15px -3px rgb(0, 0, 0, 0.1), 0 4px 6px -4px rgb(0, 0, 0, 0.1)",
  "0 20px 25px -5px rgb(0, 0, 0, 0.1), 0 8px 10px -6px rgb(0, 0, 0, 0.1)",
  "0 25px 50px -12px rgb(0, 0, 0, 0.25)",
  ...Array(18).fill("none"),
] as Shadows;

export const BASE_LABEL_WEIGHT = 500;
export const BASE_PARAGRAPH_WEIGHT = 400;

export const getBaseLinearGradient = (deg: DegType = "r", gradient: string) => {
  const degMap = {
    t: 0,
    tr: 45,
    tl: 315,
    b: 180,
    br: 135,
    bl: 225,
    r: 90,
    l: 270,
  };
  return `linear-gradient(${typeof deg === "number" ? deg : degMap[deg]}deg, ${gradient}`;
};

export class DefaultTheme {
  public readonly palette: DefaultThemePalette;
  public readonly font: DefaultThemeFont;
  public readonly spacing?: number;
  public readonly radius?: number;
  public readonly shadows?: Shadows;
  public readonly weight?: DefaultThemeWeight;

  constructor({
    palette,
    font,
    spacing = BASE_SPACING,
    radius = BASE_RADIUS,
    shadows = BASE_SHADOWS,
    weight = {
      label: BASE_LABEL_WEIGHT,
      paragraph: BASE_PARAGRAPH_WEIGHT,
    },
  }: DefaultThemeParams) {
    this.palette = {
      ...palette,

      errorDark: palette.errorDark || BASE_ERROR_DARK,
      errorMain: palette.errorMain || BASE_ERROR_MAIN,
      errorLight: palette.errorLight || BASE_ERROR_LIGHT,

      successDark: palette.successDark || BASE_SUCCESS_DARK,
      successMain: palette.successMain || BASE_SUCCESS_MAIN,
      successLight: palette.successLight || BASE_SUCCESS_LIGHT,

      warningDark: palette.warningDark || BASE_WARNING_DARK,
      warningMain: palette.warningMain || BASE_WARNING_MAIN,
      warningLight: palette.warningLight || BASE_WARNING_LIGHT,

      white: palette.white || BASE_WHITE,
      black: palette.black || BASE_BLACK,

      grey50: palette.grey50 || BASE_GREY_50,
      grey100: palette.grey100 || BASE_GREY_100,
      grey200: palette.grey200 || BASE_GREY_200,
      grey300: palette.grey300 || BASE_GREY_300,
      grey400: palette.grey400 || BASE_GREY_400,
      grey500: palette.grey500 || BASE_GREY_500,
      grey600: palette.grey600 || BASE_GREY_600,
      grey700: palette.grey700 || BASE_GREY_700,
      grey800: palette.grey800 || BASE_GREY_800,
      grey900: palette.grey900 || BASE_GREY_900,
    };
    this.font = font;
    this.spacing = spacing;
    this.radius = radius;
    this.shadows = shadows;
    this.weight = weight;
  }

  public getOptions(): ThemeOptions {
    return {
      palette: {
        primary: {
          dark: this.palette.primaryDark,
          main: this.palette.primaryMain,
          light: this.palette.primaryLight,
        },

        secondary: {
          dark: this.palette.secondaryDark,
          main: this.palette.secondaryMain,
          light: this.palette.secondaryLight,
        },

        error: {
          dark: this.palette.errorDark,
          main: this.palette.errorMain!,
          light: this.palette.errorLight,
        },

        success: {
          dark: this.palette.successDark,
          main: this.palette.successMain!,
          light: this.palette.successLight,
        },

        warning: {
          dark: this.palette.warningDark,
          main: this.palette.warningMain!,
          light: this.palette.warningLight,
        },

        common: {
          white: this.palette.white,
          black: this.palette.black,
        },

        border: {
          dark: this.palette.grey400,
          main: this.palette.grey300!,
          light: this.palette.grey200,
        },

        grey: {
          50: this.palette.grey50,
          100: this.palette.grey100,
          200: this.palette.grey200,
          300: this.palette.grey300,
          400: this.palette.grey400,
          500: this.palette.grey500,
          600: this.palette.grey600,
          700: this.palette.grey700,
          800: this.palette.grey800,
          900: this.palette.grey900,
        },

        ...this.palette.extensions,
      },

      typography: {
        fontFamily: this.font.text,
        button: {
          fontWeight: 500,
        },
      },

      spacing: this.spacing,

      shadows: this.shadows,

      shape: {
        borderRadius: this.radius,
      },

      components: {
        MuiTooltip: {
          styleOverrides: {
            tooltip: {
              backgroundColor: this.palette.secondaryMain,
            },
            arrow: {
              color: this.palette.secondaryMain,
            },
          },
        },

        // Dialog
        MuiDialog: {
          styleOverrides: {
            root: {
              "& .MuiDialogContent-root": {
                padding: this.spacing!,
                paddingBottom: this.spacing! * 2,
              },
              "& .MuiDialog-paper": {
                borderRadius: this.radius! * 4,
                padding: this.spacing! * 2,
              },
              "& .MuiDialogTitle-root": {
                padding: this.spacing!,
                paddingBottom: this.spacing! * 2,
                paddingRight: this.spacing! * 5,
              },
            },
          },
        },

        // Stepper
        MuiStepLabel: {
          styleOverrides: {
            root: {
              "&.Mui-disabled": {
                cursor: "unset",
              },
            },
          },
        },

        // Stack
        MuiStack: {
          defaultProps: {
            useFlexGap: true,
          },
        },

        // Divider
        MuiDivider: {
          styleOverrides: {
            root: {
              borderColor: this.palette.grey200,
            },
          },
        },

        // Inputs
        MuiFormControl: {
          styleOverrides: {
            root: {
              "& fieldset": { borderRadius: this.radius! * 2 },
              "& .MuiInputBase-root fieldset": {
                borderColor: this.palette.grey300,
              },
              "& .MuiInputBase-root:not(.Mui-focused):not(.Mui-disabled):not(.Mui-error):hover fieldset":
                {
                  borderColor: this.palette.grey500,
                },
              "& .Mui-focused fieldset.MuiOutlinedInput-notchedOutline": {
                borderColor: this.palette.secondaryMain,
              },
              "& .MuiInputBase-input::placeholder": {
                color: this.palette.grey400,
                opacity: 1,
              },
              "& .MuiInputLabel-root": {
                color: this.palette.grey500,
              },
              "& .Mui-disabled fieldset": {
                borderStyle: "dotted",
                borderColor: this.palette.grey400,
                color: this.palette.grey400,
              },
            },
          },
        },
        MuiFormHelperText: {
          styleOverrides: {
            root: {
              marginLeft: 0,
            },
          },
        },
        MuiInputLabel: {
          styleOverrides: {
            outlined: {
              color: this.palette.grey500,
              "&.Mui-focused.MuiInputLabel-root": {
                color: this.palette.secondaryMain,
              },
            },
          },
        },
        MuiMenu: {
          styleOverrides: {
            paper: {
              boxShadow: this.shadows![4],
            },
          },
        },

        // Typography
        MuiTypography: {
          styleOverrides: {
            root: {
              "&:is(h1, h2, h3, h4, h5, h6)": {
                fontFamily: this.font.header,
                fontWeight: 700,
              },
            },
          },
          variants: [
            {
              props: { variant: "xs" },
              style: {
                fontSize: "12px",
                lineHeight: "16px",
                letterSpacing: "0.1px",
              },
            },
            {
              props: { variant: "sm" },
              style: {
                fontSize: "14px",
                lineHeight: "20px",
                letterSpacing: "0.1px",
              },
            },
            {
              props: { variant: "md" },
              style: {
                fontSize: "16px",
                lineHeight: "24px",
                letterSpacing: "0.15px",
              },
            },
            {
              props: { variant: "lg" },
              style: {
                fontSize: "18px",
                lineHeight: "28px",
                letterSpacing: "0.15px",
              },
            },
            {
              props: { variant: "xl" },
              style: {
                fontSize: "20px",
                lineHeight: "30px",
                letterSpacing: "0.15px",
              },
            },
            {
              props: { variant: "2xl" },
              style: {
                fontSize: "24px",
                lineHeight: "32px",
                letterSpacing: "0px",
              },
            },
            {
              props: { variant: "3xl" },
              style: {
                fontSize: "30px",
                lineHeight: "36px",
                letterSpacing: "0px",
              },
            },
            {
              props: { variant: "4xl" },
              style: {
                fontSize: "48px",
                lineHeight: "54px",
                letterSpacing: "0px",
              },
            },
            {
              props: { variant: "5xl" },
              style: {
                fontSize: "60px",
                lineHeight: "66px",
                letterSpacing: "-0.5px",
              },
            },
          ],
        },

        // Table
        MuiTable: {
          styleOverrides: {
            root: {
              "&.withoutLastBorder .MuiTableBody-root .MuiTableRow-root:last-child td, &.withoutLastBorder .MuiTableBody-root .MuiTableRow-root:last-child th":
                { border: 0 },
              "&.borderless td, &.borderless th": {
                border: 0,
              },
            },
          },
        },
        MuiTableHead: {
          styleOverrides: {
            root: {
              "& .MuiTableCell-root": {
                fontWeight: this.weight!.label,
              },
            },
          },
        },
        MuiTableBody: {
          styleOverrides: {
            root: {
              "& .MuiTableCell-root": {
                fontWeight: this.weight!.paragraph,
              },
            },
          },
        },
        MuiTableRow: {
          styleOverrides: {
            root: {
              "& td, th": {
                borderColor: this.palette.grey300,
              },
              "&.borderless td, &.borderless th": {
                border: 0,
              },
              "&.MuiTableRow-hover:hover": {
                backgroundColor: this.palette.grey50,
              },
              "&.Mui-selected": {
                backgroundColor: this.palette.grey100,
              },
              "&.Mui-selected:hover": {
                backgroundColor: this.palette.grey50,
              },
            },
          },
        },

        // Paper
        MuiToolbar: {
          styleOverrides: {
            root: {
              border: 0,
              boxShadow: "none",
            },
          },
        },

        // Paper
        MuiPaper: {
          styleOverrides: {
            root: {
              boxShadow: "none",
              "&.MuiPaper-rounded": {
                borderRadius: this.radius! * 3,
              },
              "&.MuiPaper-elevation": {
                border: `1px solid ${this.palette.grey200}`,
                borderRadius: this.radius! * 3,
              },
            },
          },
        },

        // Button
        MuiButton: {
          styleOverrides: {
            root: {
              fontWeight: 700,
              textTransform: "none",
              borderRadius: this.radius! * 2,
            },
            contained: {
              boxShadow: this.shadows![2],
              "&:hover": {
                boxShadow: this.shadows![2],
              },
              "&:focus": {
                boxShadow: this.shadows![2],
              },
            },
            containedPrimary: {
              color: this.palette.white,
            },
            containedSecondary: {
              color: this.palette.white,
            },
            containedError: {
              color: this.palette.white,
            },
            containedSuccess: {
              color: this.palette.white,
            },
            containedWarning: {
              color: this.palette.white,
            },
          },
        },

        // Toggle button group
        MuiToggleButtonGroup: {
          styleOverrides: {
            root: {
              "&.MuiToggleButtonGroup-root": {
                border: `solid ${this.palette.grey300} 1px`,
                borderRadius: this.radius! * 2,
                overflow: "hidden",
                padding: 0,
              },
            },
          },
        },

        // Toggle button
        MuiToggleButton: {
          styleOverrides: {
            root: {
              "&.MuiToggleButton-root": {
                border: 0,
                padding: 0,
                textTransform: "none",
                fontWeight: 700,
              },

              "&.MuiToggleButtonGroup-lastButton": {
                marginLeft: 0,
              },

              "&.Mui-selected": {
                backgroundColor: this.palette.grey100,
              },
            },
          },
        },
      },
    };
  }

  public create(): ReturnType<typeof createTheme> {
    return createTheme(this.getOptions());
  }
}
